@use "variables"
@use "bootstrap_overrides"
@use "../mixins/all" as mixins

$button-border-size: 0.125rem
$button-padding-x: 1.3125rem
$button-padding-y: 0.475rem

@mixin button-font-and-paddings-1
  @include mixins.thrv3-fonts-bold
  font-size: variables.$thrv3-font-size-17
  font-weight: bold
  line-height: 1.4375rem

  padding: $button-padding-y $button-padding-x $button-padding-y $button-padding-x

@mixin filled-button
  @include mixins.thrv3-fonts-bold
  width: 10.625rem
  height: initial
  border-radius: 0.3125rem
  padding: 0.5rem 1rem
  font-size: variables.$thrv3-font-size-16
  font-weight: variables.$thrv3-font-weight-bold

@mixin thrv3-button-primary-lg($color, $height:"45px")
  @extend .btn
  @include mixins.thrv3-fonts-semibold

  color: variables.$thrv3-colors-white
  background-color: $color
  height: unquote($height)
  padding: 0.5rem 1rem

  &:hover
    color: variables.$thrv3-colors-white
    background-color: darken($color, 10%)

  &:active
    color: variables.$thrv3-colors-white
    background-color: $color

@mixin thrv3-button-pill-normal($color, $background)
  @extend .btn
  @include bootstrap_overrides.button-variant($background, $color, $hover-border: $color, $active-border: $color)
  @include mixins.thrv3-fonts-bold

  font-size: variables.$thrv3-font-size-17
  color: $color
  border-radius: 28px
  border: none
  width: 125px
  padding: 3px 2px

  &:hover, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active
    background-color: variables.$thrv3-colors-transparent
    color: $color

@mixin thrv3-button-primary-orange-outlined
  @include button-font-and-paddings-1

  color: variables.$thrv3-colors-safety-orange
  background-color: white

  border-radius: 5px

  border: 2px solid variables.$thrv3-colors-safety-orange

  &:hover
    background-color: variables.$thrv3-colors-safety-orange-light

  &:active
    background-color: variables.$thrv3-colors-safety-orange
    color: white

@mixin thrv3-button-secondary-orange-filled
  @extend .btn
  @include filled-button
  @include bootstrap_overrides.button-variant(variables.$thrv3-colors-safety-orange, variables.$thrv3-colors-white, $hover-background: variables.$thrv3-colors-hover-button-primary, $hover-border: variables.$thrv3-colors-white, $active-background: variables.$thrv3-colors-royal-blue, $active-border: variables.$thrv3-colors-royal-blue)

@mixin thrv3-button-secondary-orange-light-filled
  @extend .btn
  @include filled-button
  @include bootstrap_overrides.button-variant(variables.$thrv3-colors-safety-orange-light, variables.$thrv3-colors-white, $hover-background: variables.$thrv3-colors-safety-orange-mild, $hover-border: variables.$thrv3-colors-white, $active-background: variables.$thrv3-colors-safety-orange-mild, $active-border: variables.$thrv3-colors-safety-orange-mild)

  color: variables.$thrv3-colors-safety-orange

  &:hover, &:not(:disabled):not(.disabled):active
    color: variables.$thrv3-colors-safety-orange

@mixin thrv3-button-secondary-orange-linked
  @extend .btn
  @include filled-button
  @include bootstrap_overrides.button-variant(variables.$thrv3-colors-white, variables.$thrv3-colors-white, $hover-background: variables.$thrv3-colors-safety-orange-light, $hover-border: variables.$thrv3-colors-safety-orange-light, $active-background: variables.$thrv3-colors-safety-orange-light, $active-border: variables.$thrv3-colors-safety-orange-light)

  color: variables.$thrv3-colors-safety-orange

  &:hover, &:not(:disabled):not(.disabled):active
    color: variables.$thrv3-colors-safety-orange

@mixin thrv3-button-primary-orange
  @include button-font-and-paddings-1

  color: white
  background-color: variables.$thrv3-colors-safety-orange

  border-radius: 8px
  border: 0.5px solid variables.$thrv3-colors-safety-orange

  &:hover, &:active
    background-color: variables.$thrv3-colors-hover-button-primary
    border-color: variables.$thrv3-colors-hover-button-primary

@mixin thrv3-button-linked($color, $background)
  @extend .btn
  @include button-font-and-paddings-1

  cursor: pointer
  color: $color
  border-radius: 0.5rem

  &:hover
    color: $color
    background-color: $background

  &:active
    color: $color
    border-color: $color
    background-color: $background

  &:focus
    box-shadow: none
