@use "../redesign/core/variables"

.brand-name
  &--big
    width: 186px
    height: 71px

.error
  margin-top: 10rem

  &__content
    text-align: center

  &__code
    font-weight: 700
    font-size: 2.188rem

  &__hint
    font-size: 1.063rem

.invalid-feedback
  text-align: center
  font-size: 85%

.alert-warning
  color: variables.$thrv3-colors-dark-grey-2

  .alert-link
    color: darken(variables.$thrv3-colors-dark-grey-2, 10%)

    &:hover
      color: lighten(variables.$thrv3-colors-dark-grey-2, 10%)

.thrv
  color: variables.$thrv3-colors-safety-orange
  word-spacing: -0.25rem