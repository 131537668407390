@use "../../redesign/all" as redesign
@use "mixins/not-required"

.projects
  @include redesign.content-left-and-right-paddings
  margin-top: map-get(redesign.$spacers, 4)

  form
    .optional
      @include not-required.not-required
