@use "../core/variables" as variables

a
  text-decoration: none

body
  background-color: variables.$thrv3-colors-white
  font-family: variables.$thrv3-fonts-proxima-nova
  width: 100%
  height: 100%

  padding: 0
  margin: 0

.main-layout
  display: flex
  flex-direction: column
  min-height: 100vh

.content-with-sidebar
  display: flex
  flex-direction: row
  align-items: stretch
  flex: 1

.content
  flex-grow: 1
  position: relative

  &--with-grey-background
    background-color: variables.$thrv3-colors-mild-white

  &--admin
    padding: 1.5rem 1rem

.sidebar
  flex-grow: 0
  position: relative
  z-index: 1

.demo-project-banner
  width: 100%
  background-color: variables.$thrv3-colors-font-blue
  font-size: variables.$thrv3-font-size-22
  line-height: 2rem
  color: variables.$thrv3-colors-white
  padding: 0.813rem 9.5rem 0.75rem
  text-align: center

  &__main-text
    font-weight: bold

  i
    vertical-align: text-bottom
    font-weight: bold
