@use "mixins/sup-icon"
@use "../../redesign/core/variables" as core_variables
@use "../../redesign/all" as redesign

.company_settings
  @include redesign.content-left-and-right-paddings
  margin-top: map-get(redesign.$spacers, 4)

  .setting
    @include redesign.make-row
    margin-bottom: 1rem

    .title
      @include redesign.make-col-ready

      @include redesign.media-breakpoint-up(sm)
        @include redesign.make-col(12)
      @include redesign.media-breakpoint-up(md)
        @include redesign.make-col(3)
      font-weight: redesign.$thrv3-font-weight-bold

    .value
      @include redesign.make-col-ready

      @include redesign.media-breakpoint-up(sm)
        @include redesign.make-col(12)
      @include redesign.media-breakpoint-up(md)
        @include redesign.make-col(3)

      .company-logo
        @include redesign.make-col(10)
        max-height: 40px

    .deletion-text
      @include redesign.make-col-ready

      @include redesign.media-breakpoint-up(sm)
        @include redesign.make-col(12)
      @include redesign.media-breakpoint-up(md)
        @include redesign.make-col(5)
      font-weight: redesign.$thrv3-font-weight-bold

    .delete-link
      @include redesign.make-col-ready

      @include redesign.media-breakpoint-up(sm)
        @include redesign.make-col(12)
      @include redesign.media-breakpoint-up(md)
        @include redesign.make-col(1)

  .hide_legacy_analyses_form
    .hide_legacy_analyses
      display: none

      + label
        position: relative
        padding-left: 28px
        cursor: pointer

        &:before
          content: ""
          position: absolute
          left: 0
          width: 20px
          height: 20px
          border-radius: 4px

        &:after
          content: ""
          position: absolute
          left: 7px
          top: 2px
          width: 6px
          height: 12px
          border: solid white
          border-width: 0 2px 2px 0
          transform: rotate(45deg)
          opacity: 0

    .hide_legacy_analyses:checked
      + label
        &:before
          background: core_variables.$thrv3-colors-safety-orange
        &:after
          opacity: 1

    .hide_legacy_analyses:not(:checked)
      + label
        &:before
          border: 2px solid core_variables.$thrv3-colors-light-grey-7
        &:after
          opacity: 0

  .edit-link
    @include sup-icon.sup-icon

  table
    th
      border-top: none
    td
      word-wrap: break-word
      word-break: break-all

    tr:first-child
      td
        border-top: none

  .pending-invites
    &__table
      &__invite
        &__expired
          font-weight: redesign.$thrv3-font-weight-bold
          color: redesign.$thrv3-colors-red

        &__delete_link
          float: right
          margin-left: 0.5rem
