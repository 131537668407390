@use "../core/variables" as variables
@use "../mixins/all" as mixins

@mixin reset-spacers
  margin: 0
  padding: 0

@mixin title
  font-size: variables.$thrv3-font-size-title
  font-weight: bold
  line-height: variables.$thrv3-line-height-title

.sub-header
  @include mixins.content-left-and-right-paddings
  margin-top: 1.8rem
  margin-bottom: 1.6rem

  h1
    @include title
    @include reset-spacers
  p
    font-size: variables.$thrv3-font-size-17
    font-weight: normal
    line-height: variables.$thrv3-line-height-regular
    @include reset-spacers

.sub-header-job-dropdown
  .thrv-dropdown-toggle
    font-size: variables.$thrv3-font-size-22
    margin-bottom: 0.5rem

    &__prefix
      @include title
      margin-right: 0.3rem
