@use "../../redesign/all" as redesign

.bs-wizard
	padding: 0 0 10px 0
	flex-wrap: nowrap
	& > .bs-wizard-step
		padding: 0
		position: relative
		& > .bs-wizard-dot
			position: absolute
			width: 13px
			height: 13px
			display: block
			background: redesign.$thrv3-colors-blue-2
			top: 45px
			left: 52.5%
			margin-top: -15px
			margin-left: -15px
			border-radius: 50%
		& > .progress
			position: relative
			border-radius: 0px
			height: 4px
			box-shadow: none
			margin: 11px 0
			& > .progress-bar
				width: 0px
				box-shadow: none
				background: redesign.$thrv3-colors-blue-2
		&.complete
			& > .progress
				& > .progress-bar
					width: 100%
			&.active
				& > .progress
					& > .progress-bar
						width: 100%
		&.active
			& > .progress
				& > .progress-bar
					width: 50%
			& > .bs-wizard-stepnum
				font-weight: redesign.$thrv3-font-weight-bold
		&:first-child
			&.active
				& > .progress
					& > .progress-bar
						width: 0%
			&.complete
				&.active
					& > .progress
						& > .progress-bar
							width: 100%
			& > .progress
				left: 50%
				width: 50%
		&:last-child
			&.active
				& > .progress
					& > .progress-bar
						width: 100%
			& > .progress
				width: 50%
		&.disabled
			& > .bs-wizard-dot
				background-color: redesign.$thrv3-colors-hr-border
				&:after
					opacity: 0
			a
				&.bs-wizard-dot
					pointer-events: none
