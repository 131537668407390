@use "../core/variables"
@use "../core/bootstrap_overrides"
@use "../core/buttons"

.password-reset
  @include bootstrap_overrides.make-row
  justify-content: center

  &__logo
    text-align: center
    max-width: 10rem
    margin-bottom: 3rem

  &__content
    @include bootstrap_overrides.make-col-ready

    @include bootstrap_overrides.media-breakpoint-up(xs)
      @include bootstrap_overrides.make-col(12)

    @include bootstrap_overrides.media-breakpoint-up(sm)
      @include bootstrap_overrides.make-col(12)

    @include bootstrap_overrides.media-breakpoint-up(md)
      @include bootstrap_overrides.make-col(4)

    @include bootstrap_overrides.media-breakpoint-up(lg)
      @include bootstrap_overrides.make-col(4)

    @include bootstrap_overrides.media-breakpoint-up(xl)
      @include bootstrap_overrides.make-col(4)

  &__flash
    @include bootstrap_overrides.make-row
    justify-content: center

  &__flash-message
    text-align: center

    @include bootstrap_overrides.make-col-ready

    @include bootstrap_overrides.media-breakpoint-up(xs)
      @include bootstrap_overrides.make-col(12)

    @include bootstrap_overrides.media-breakpoint-up(sm)
      @include bootstrap_overrides.make-col(12)

    @include bootstrap_overrides.media-breakpoint-up(md)
      @include bootstrap_overrides.make-col(7)

    @include bootstrap_overrides.media-breakpoint-up(lg)
      @include bootstrap_overrides.make-col(7)

    @include bootstrap_overrides.media-breakpoint-up(xl)
      @include bootstrap_overrides.make-col(7)

  &__input, &__button, &__hint
    text-align: center

    ul
      list-style-type: none
      padding: 0

  &__header
    text-align: center

    &__title
      font-size: variables.$thrv3-font-size-35
      font-weight: variables.$thrv3-font-weight-bold
      color: variables.$thrv3-colors-dark-grey
      margin-bottom: 1.625rem

    &__help_text
      list-style-type: none
      font-size: variables.$thrv3-font-size-17
      color: variables.$thrv3-colors-dark-grey
      margin: 0 0 3.5rem 0
      padding: 0

  &__url_expired
    text-align: center
    width: max-content
    text-align: left
    margin-left: auto
    margin-right: auto

    &__title
      font-size: variables.$thrv3-font-size-35
      font-weight: variables.$thrv3-font-weight-bold
      color: variables.$thrv3-colors-dark-grey
      margin-bottom: 1.625rem

    &__help_text
      font-size: variables.$thrv3-font-size-15
      color: variables.$thrv3-colors-dark-grey
      width: 28rem

    &__forgot_password_link
      &__container
        font-size: variables.$thrv3-font-size-17
        font-weight: variables.$thrv3-font-weight-bold
        text-align: left
        margin-bottom: 1.5rem

  &__input
    width: 22rem
    height: 2.8125rem
    padding: 0.625rem
    border: 1px solid variables.$thrv3-colors-light-grey-7
    border-radius: 0.3125rem
    font-size: variables.$thrv3-font-size-16
    color: variables.$thrv3-colors-light-grey-12
    margin: 0 auto
    text-align: left

  &__button
    @include buttons.thrv3-button-primary-lg(variables.$thrv3-colors-safety-orange)
    display: block
    margin-top: 1.875rem
    padding: 0.375rem 0.75rem
    height: 2.375rem
    font-size: variables.$thrv3-font-size-16
    font-weight: variables.$thrv3-font-weight-bold
    border-radius: 0.3125rem
