@use "../core/variables"
@use "../core/bootstrap_overrides"
@use "../core/buttons"

.registration
  @include bootstrap_overrides.make-row
  justify-content: center

  &__logo
    text-align: center
    max-width: 10rem
    margin-bottom: 3.125rem

  &__content
    @include bootstrap_overrides.make-col-ready

    @include bootstrap_overrides.media-breakpoint-up(xs)
      @include bootstrap_overrides.make-col(12)

    @include bootstrap_overrides.media-breakpoint-up(sm)
      @include bootstrap_overrides.make-col(12)

    @include bootstrap_overrides.media-breakpoint-up(md)
      @include bootstrap_overrides.make-col(4)

    @include bootstrap_overrides.media-breakpoint-up(lg)
      @include bootstrap_overrides.make-col(4)

    @include bootstrap_overrides.media-breakpoint-up(xl)
      @include bootstrap_overrides.make-col(4)

    &__flash
      @include bootstrap_overrides.make-row
      justify-content: center

    &__flash-message
      text-align: center

      @include bootstrap_overrides.make-col-ready

      @include bootstrap_overrides.media-breakpoint-up(xs)
        @include bootstrap_overrides.make-col(12)

      @include bootstrap_overrides.media-breakpoint-up(sm)
        @include bootstrap_overrides.make-col(12)

      @include bootstrap_overrides.media-breakpoint-up(md)
        @include bootstrap_overrides.make-col(7)

      @include bootstrap_overrides.media-breakpoint-up(lg)
        @include bootstrap_overrides.make-col(7)

      @include bootstrap_overrides.media-breakpoint-up(xl)
        @include bootstrap_overrides.make-col(7)

    &__header
      padding-top: 1.875rem

    &__invitation_expired
      &__container
        color: variables.$thrv3-colors-dark-grey
        font-size: variables.$thrv3-font-size-17

        &__header
          text-align: center
          font-weight: variables.$thrv3-font-weight-bold
          font-size: variables.$thrv3-font-size-35
          margin-bottom: 1.5625rem

        &__message_container
          width: max-content
          margin: 0 auto

  &__content__header, &__input, &__button, &__hint
    text-align: center

  &__button
    display: block
    @include buttons.thrv3-button-primary-lg(variables.$thrv3-colors-safety-orange)
