@use "../../redesign/all" as redesign

.create-account
  @include redesign.make-row
  justify-content: center

  &__content
    @include redesign.make-col-ready

    @include redesign.media-breakpoint-down(xl)
      @include redesign.make-col(4)
    @include redesign.media-breakpoint-down(md)
      @include redesign.make-col(8)
    @include redesign.media-breakpoint-down(xs)
      @include redesign.make-col(12)

  &__flash
    @include redesign.make-col-ready

  &__flash-message
    @include redesign.make-col-ready

    @include redesign.media-breakpoint-down(xl)
      @include redesign.make-col(4)
    @include redesign.media-breakpoint-down(md)
      @include redesign.make-col(8)
    @include redesign.media-breakpoint-down(xs)
      @include redesign.make-col(12)

    text-align: center

  &__header, &__input, &__button, &__hint
    text-align: center

  &__button
    display: block
    @include redesign.btn-thrv-primary
