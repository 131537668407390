@use "../redesign/core/variables" as variables
@use "../redesign/core/bootstrap_overrides"

= table-question
  width: 100%
  margin-bottom: bootstrap_overrides.$spacer
  background-color: bootstrap_overrides.$table-bg  // Reset for nesting within parents with `background-color`.

  th, td
    padding: bootstrap_overrides.$table-cell-padding
    vertical-align: center
    border: bootstrap_overrides.$table-border-width solid black

  thead th
    vertical-align: bottom
    border-bottom: bootstrap_overrides.$table-border-width solid black

  tbody + tbody
    border-top: bootstrap_overrides.$table-border-width solid black

  th, td
    .form-group
      margin: 0
      position: relative

      input.currency
        display: inline-block
        padding-left: 1.5rem

      input.percent
        display: inline-block
        padding-right: 1.5rem
        text-align: right

      .percent-sign
        position: absolute
        right: 0.5rem
        top: 0.5rem

      .currency-sign
        position: absolute
        left: 0.5rem
        top: 0.5rem

  tbody > tr.total > td, tbody > tr.total > th
    background-color: variables.$thrv3-colors-light-grey-21
  thead > tr > th, tbody > tr > td.total
    background-color: variables.$thrv3-colors-light-grey-22
