@use "../../redesign/all" as redesign
@use "mixins/not-required"

.companies
  @include redesign.content-left-and-right-paddings
  margin-top: map-get(redesign.$spacers, 4)

  .welcome
    margin-bottom: 3rem

    .title
      display: block
      text-align: center

      &--normal
        font-size: 3rem
        color: redesign.$thrv3-colors-black
        display: inline

      &--highlight
        font-size: 3rem
        color: redesign.$thrv3-colors-safety-orange
        display: inline
        font-weight: redesign.$thrv3-font-weight-bold

    .help
      font-size: 1rem
      text-align: center

  &__header
    &__title
      @include redesign.thrv3-fonts-medium
      font-size: redesign.$thrv3-font-size-25
      color: redesign.$thrv3-colors-dark-grey
      font-weight: redesign.$thrv3-font-weight-500
      line-height: redesign.$thrv3-line-height-course-header

  &__contact_us
    &__wrapper
      @include redesign.thrv3-fonts-medium
      font-size: redesign.$thrv3-font-size-16
      color: redesign.$thrv3-colors-dark-grey
      font-weight: redesign.$thrv3-font-weight-500
      line-height: 1.3125rem
      padding: 0.7rem 0

    &__link
      color: redesign.$thrv3-colors-safety-orange

  &__new_organization_form
    &__wrapper
      font-size: redesign.$thrv3-font-size-16
      color: redesign.$thrv3-colors-light-grey-7
      width: 50%

    &__label
      font-size: redesign.$thrv3-font-size-16
      margin-bottom: 0.625rem
      color: redesign.$thrv3-colors-dark-grey
      font-weight: redesign.$thrv3-font-weight-400
      line-height: 1.25rem

    &__input
      height: 2.5rem
      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-400
      line-height: 1.25rem
      color: redesign.$thrv3-colors-dark-grey
      border: 0.5px solid redesign.$thrv3-colors-light-grey-7
      border-radius: 0.3125rem

      &.form-control
        padding-right: 4rem

        &:focus
          color: redesign.$thrv3-colors-dark-grey

        &.is-valid
          background-image: none

      &__wrapper
        position: relative
        margin-bottom: 1.5rem

        &.form-group-valid
          .companies__new_organization_form__input__saved_text
            display: block
            position: absolute
            right: 1rem
            top: 50%
            font-size: redesign.$thrv3-font-size-16
            color: redesign.$thrv3-colors-dark-grey

      &__saved_text
        display: none

    &__project_name
      &__info_tooltip
        sup
          top: 0

        i
          color: redesign.$thrv3-colors-tooltip-background-blue
          font-size: redesign.$thrv3-font-size-16

    &__actions
      margin-top: 1.0625rem
      display: flex
      flex-direction: row-reverse

    &__submit_button
      @include redesign.thrv3-button-primary-lg(redesign.$thrv3-colors-safety-orange)

      font-weight: 700
      height: 2.5rem
      margin-left: 0.625rem
      border-radius: 0.3125rem
      padding: 0rem 1.35rem
      font-size: redesign.$thrv3-font-size-16
      box-shadow: 0 2px 2px redesign.$thrv3-colors-shadow-4

    &__cancel_button
      @include redesign.thrv3-button-linked(redesign.$thrv3-colors-dark-grey, redesign.$thrv3-colors-light-grey-33)

      display: flex
      align-items: center
      height: 2.5rem
      border-radius: 0.1875rem
      padding: 0rem 0.75rem
      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-regular
      line-height: 1.25rem

      &:hover
        color: redesign.$thrv3-colors-light-grey-35

  form
    .optional
      @include not-required.not-required

      .upload-button
        color: #343a40
        padding: 0.5rem 1.7rem
        line-height: normal
      
      .organization_form_color
        label
          margin-right: 8px
        input
          display: inline
          width: 90px
