@use "../../../stylesheets/redesign/all" as redesign

.dashboard
  &__course_box
    &__thumb, &__thumb--locked
      position: relative
      z-index: 1
      border: 2px solid redesign.$thrv3-colors-grey
      text-align: center
      border-radius: 8px

      &__icon
        font-size: 1.5rem !important
        position: absolute
        z-index: 2
        top: 5px
        right: 5px

        &--locked
          color: redesign.$thrv3-colors-grey
        &--viewed
          color: redesign.$thrv3-colors-safety-orange

      &:hover
        border: 2px solid redesign.$thrv3-colors-safety-orange

    &--completed
      opacity: 0.6

  &__button
    @extend .col-sm-4
    @extend .col-md-2
    @include redesign.thrv3-fonts-bold
    margin: auto
    justify-content: center
    line-height: 1rem

  @mixin inline-button-large
    @extend .rounded-circle
    @extend .p-0

    width: 60px
    height: 60px
    margin: auto

  &__course_status
    text-align: center
    margin: auto
    margin-top: 3rem

    .text
      @include redesign.thrv3-fonts-bold
      color: redesign.$thrv3-colors-safety-orange

    &--not-completed
      @include inline-button-large
      background-color: redesign.$thrv3-colors-white
      border: 2px solid redesign.$thrv3-colors-grey

    &--completed
      @include inline-button-large
      background-color: redesign.$thrv3-colors-safety-orange
      box-shadow: 0 0 0px 3px redesign.$thrv3-colors-border-orange
      color: redesign.$thrv3-colors-white
      font-size: 1.5rem

      .icon
        position: relative
        top: 40%
        left: 40%
        transform: translate(-50%, -50%)
        width: 10px
        height: 10px
        display: block
