@use "../../redesign/all" as redesign

.thanks
  @include redesign.make-row
  justify-content: center

  &__content
    @include redesign.make-col-ready

    @include redesign.media-breakpoint-up(xs)
      @include redesign.make-col(12)

    @include redesign.media-breakpoint-up(sm)
      @include redesign.make-col(12)

    @include redesign.media-breakpoint-up(md)
      @include redesign.make-col(6)

    @include redesign.media-breakpoint-up(lg)
      @include redesign.make-col(7)

    @include redesign.media-breakpoint-up(xl)
      @include redesign.make-col(7)

  &__header, &__hint
    text-align: center
