@use "../core/_variables" as variables

@mixin content-left-and-right-paddings
  padding-left: 1.40rem
  padding-right: 1.40rem

@mixin content-top-and-bottom-paddings
  padding-top: 1rem
  padding-bottom: 1rem

@mixin make-flex-row
  display: flex
  flex-direction: row
  justify-content: flex-end

@mixin make-flex-row-end
  @include make-flex-row
  justify-content: flex-end

@mixin make-center
  display: flex
  align-items: center
  justify-content: center

@mixin thrv3-fonts-regular
  font-weight: 400

@mixin thrv3-fonts-medium
  font-weight: 500

@mixin thrv3-fonts-semibold
  font-weight: 600

@mixin thrv3-fonts-bold
  font-weight: 700
