@use "variables"

.thrv-logo
  width: 75px
  height: 29.98px
  background-image: url("../../../images/logo.svg")

.icon
  display: inline-block
  background-repeat: no-repeat
  background-position-x: center
  background-position-y: center

  &.chevron-down
    &--orange
      width: 12px
      height: 6px
      background-image: url("../../../images/icons/chevron-down-orange.svg")

    &--dark-grey
      width: 12px
      height: 6px
      background-image: url("../../../images/icons/chevron-down-dark-grey.svg")

  &.close
    &--orange
      width: 20px
      height: 20px
      background-image: url("../../../images/icons/close-orange.svg")

    &--red-small
      width: 27px
      height: 27px
      background-image: url("../../../images/icons/close-red-small.svg")

    &--grey
      width: 20px
      height: 20px
      background-image: url("../../../images/icons/close-grey.svg")

  &.ok
    &--orange
      width: 27px
      height: 27px
      background-image: url("../../../images/icons/ok-orange.svg")

  &.bulb
    &--orange
      width: 26px
      height: 26px
      background-image: url("../../../images/icons/bulb-orange.svg")

    &--grey
      width: 26px
      height: 26px
      background-image: url("../../../images/icons/bulb-grey.svg")

  &.edit
    &--orange
      width: 35px
      height: 35px
      background-image: url("../../../images/icons/edit-orange.svg")

    &--grey
      width: 35px
      height: 35px
      background-image: url("../../../images/icons/edit-grey.svg")

  &.drag-dots
    &--grey
      width: 6px
      height: 14px
      background-image: url("../../../images/icons/drag-dots-grey.svg")

  &.file
    &--orange
      width: 26px
      height: 26px
      background-image: url("../../../images/icons/file-orange.svg")

    &--orange-filled
      width: 26px
      height: 26px
      background-image: url("../../../images/icons/file-orange-filled.svg")

    &--grey
      width: 26px
      height: 26px
      background-image: url("../../../images/icons/file-grey.svg")

    &--grey-filled
      width: 26px
      height: 26px
      background-image: url("../../../images/icons/file-grey-filled.svg")

.make-color-safety-orange
  color: variables.$thrv3-colors-safety-orange
