@use "../../redesign/all" as redesign

@mixin payment_details_form
  .payment_details_form
    &__container
      padding: 0 0.625rem

    &__header
      padding: 1.875rem 0
      text-align: left

    &__label
      margin-bottom: 0.625rem

      .fa
        font-size: redesign.$thrv3-font-size-18
        color: redesign.$thrv3-colors-tooltip-background-blue

    &__input
      border: 0.5px solid redesign.$thrv3-colors-light-grey-7
      border-radius: 0.3125rem
      color: redesign.$thrv3-colors-light-grey-7
      padding: 0.625rem 0.5rem
      height: 2.5rem

      &:focus
        border-color: redesign.$thrv3-colors-font-blue

      &__container
        margin-bottom: 2rem

.billing
  @include redesign.content-left-and-right-paddings
  margin-top: 1.5rem
  margin-bottom: 1.5rem

  &__header
    &__title
      @include redesign.thrv3-fonts-medium
      font-size: redesign.$thrv3-font-size-25
      font-weight: redesign.$thrv3-font-weight-500
      color: redesign.$thrv3-colors-dark-grey

    &__contact_us
      &__wrapper
        font-size: redesign.$thrv3-font-size-16
        font-weight: redesign.$thrv3-font-weight-400
        color: redesign.$thrv3-colors-dark-grey
        padding-top: 0.2rem

  &__upgrade_plan--btn, &__invite_users--btn
    @include redesign.thrv3-button-primary-orange

    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-600
    padding: 0
    width: 4.75rem
    height: 2rem
    margin-bottom: 0.5rem

    &:hover
      background-color: redesign.$thrv3-colors-orange-3
      color: redesign.$thrv3-colors-white

  &__invite_users--btn
    padding: 0.375rem 0.75rem

  &__transfer_ownership_link
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400

  &__downgrade_link
    color: redesign.$thrv3-colors-light-grey-7
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400

  &__delete_link
    color: redesign.$thrv3-colors-light-grey-7
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400

    &:hover
      color: redesign.$thrv3-colors-red

  &__resubscribe_link, &__subscribe_link
    color: redesign.$thrv3-colors-light-grey-7
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400

  &__content
    padding-top: 0.7rem

    &__title
      @include redesign.thrv3-fonts-bold
      font-size: redesign.$thrv3-font-size-20
      font-weight: redesign.$thrv3-font-weight-bold
      color: redesign.$thrv3-colors-dark-grey

    &__subscription_status, &__credit_card
      &__wrapper
        @include redesign.thrv3-fonts-medium
        font-size: redesign.$thrv3-font-size-16
        color: redesign.$thrv3-colors-dark-grey
        display: flex
        align-items: center
        padding-top: 0.45rem

      &__title
        min-width: 9rem

      &__text
        margin-left: 17.5rem

    &__credit_card
      &__edit_link
        margin-left: 0.5rem

        &:not(:hover)
          @extend .icon
          @extend .edit--grey

        &:hover
          @extend .icon
          @extend .edit--orange

    &__free_members
      &__table
        &__wrapper
          margin-top: 2rem

      &__checkbox
        @extend .custom-control
        @extend .custom-checkbox
        display: flex
        margin-left: 1.875rem

      &__checkbox--label
        @extend .custom-control-label
        @extend .float-right
        @extend .mr-5
        vertical-align: top

      &__checkbox--label::before
        border: 2px solid redesign.$thrv3-colors-safety-orange
        background-color: white

      &__checkbox--input
        @extend .custom-control-input

        &:focus ~ .billing__free_members__checkbox--label::before
          box-shadow: none

        &:checked ~ .billing__free_members__checkbox--label::before
          background-color: redesign.$thrv3-colors-safety-orange

    &__paid_users
      &__table
        &__wrapper
          margin-top: 2rem

    &__inactive_users
      &__table
        &__wrapper
          margin-top: 2rem

    &__table
      width: 100%

      &__header
        @include redesign.thrv3-fonts-bold
        font-size: redesign.$thrv3-font-size-20
        color: redesign.$thrv3-colors-dark-grey
        height: 2rem
        margin-bottom: 0.5rem

      tr
        display: flex
        font-size: redesign.$thrv3-font-size-16
        color: redesign.$thrv3-colors-dark-grey

      thead
        th
          border-top: none
          font-size: redesign.$thrv3-font-size-16
          font-weight: redesign.$thrv3-font-weight-400
          color: redesign.$thrv3-colors-dark-grey
          align-self: flex-end

        tr
          margin-bottom: 1rem

      tbody
        tr
          min-height: 3.125rem
          border-radius: 0.5rem

          &.billing__content__table__row
            &:hover
              background-color: redesign.$thrv3-colors-light-grey-31
              color: redesign.$thrv3-colors-light-grey-7

              .billing__delete_link, .billing__downgrade_link
                color: redesign.$thrv3-colors-red

              .billing__resubscribe_link, .billing__subscribe_link
                color: redesign.$thrv3-colors-safety-orange

        td
          word-wrap: break-word
          word-break: break-all
          align-self: center

      &--no_records
        thead
          tr
            margin-bottom: 0

        tbody
          tr
            min-height: auto

  &__tooltip
    z-index: 500
    position: absolute
    left: 7em
    white-space: nowrap
    min-width: fit-content
    max-height: fit-content
    color: redesign.$thrv3-colors-safety-orange
    background-color: white
    font-size: 14px
    font-weight: 400
    padding: 5px 10px 5px 10px
    border: solid 1px redesign.$thrv3-colors-safety-orange

  &__tooltip::after
    content: ""
    position: absolute
    top: 50%
    right: 100%
    margin-top: -5px
    border-width: 5px
    border-style: solid
    border-color: transparent redesign.$thrv3-colors-safety-orange transparent transparent

  .fa-question-circle
    color: redesign.$thrv3-colors-safety-orange
  .form-control-label
    abbr[title='required']
      display: none

#deleteMemberModal, #downgradeMemberModal
  .modal-dialog
    max-width: 37.5rem

  .modal-content
    box-shadow: 0 0.5rem 1.25rem redesign.$thrv3-colors-shadow-3
    border-radius: 0.5rem

  .modal-body
    text-align: center
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400
    color: redesign.$thrv3-colors-dark-grey

    .modal-title
      font-size: redesign.$thrv3-font-size-20
      font-weight: redesign.$thrv3-font-weight-bold
      padding: 1rem 0

    .user-name
      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-bold

    .cancel-button
      @include redesign.thrv3-button-linked(redesign.$thrv3-colors-light-grey-7, redesign.$thrv3-colors-light-grey-33)

      height: 2rem
      border-radius: 0.1875rem
      padding: 0.375rem 0.75rem
      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-regular
      line-height: 1.25rem

      &:hover
        color: redesign.$thrv3-colors-light-grey-35

    .confirm-button
      @include redesign.thrv3-button-primary-lg(redesign.$thrv3-colors-red)

      height: 2rem
      margin-left: 0.625rem
      border-radius: 0.3125rem
      padding: 0.375rem 0.75rem
      font-size: redesign.$thrv3-font-size-16
      line-height: 1.25rem

    .submit_buttons
      &__container
        margin: 2rem auto 1rem auto

#transferOwnershipModal
  .modal-dialog
    max-width: 37.5rem

  .modal-content
    box-shadow: 0 0.5rem 1.25rem redesign.$thrv3-colors-shadow-3
    border-radius: 0.5rem

  .modal-body
    text-align: center
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400
    color: redesign.$thrv3-colors-dark-grey

    .modal-title
      font-size: redesign.$thrv3-font-size-20
      font-weight: redesign.$thrv3-font-weight-bold
      padding-bottom: 2.5rem

    .user
      &__dropdown
        @extend .dropdown

        font-size: redesign.$thrv3-font-size-19
        color: redesign.$thrv3-colors-black

        &__wrapper
          margin: 0.5rem auto 3.125rem
          width: 20rem

        &__toggle
          display: flex
          align-items: center
          color: redesign.$thrv3-colors-light-grey-7

          &__selected_user
            text-align: left
            font-size: redesign.$thrv3-font-size-22
            color: redesign.$thrv3-colors-black

          &__caret
            margin-left: auto

            &::before
              content: "\f105"
              font-family: FontAwesome
              color: redesign.$thrv3-colors-safety-orange

        &__options
          &__wrapper
            overflow-y: scroll
            max-height: 12.5rem

        &__autocomplete_text
          font-size: redesign.$thrv3-font-size-16
          color: redesign.$thrv3-colors-light-grey-7

        &.show
          border-color: redesign.$thrv3-colors-safety-orange

          .user__dropdown__toggle__caret::before
            content: "\f107"
            font-family: FontAwesome

        .dropdown-menu
          width: 100%
          border: 0.3px solid redesign.$thrv3-colors-light-grey-7
          box-shadow: 0 0.1875rem 1.25rem redesign.$thrv3-color-dropdown-shadow-2
          border-radius: 0.3125rem

        .dropdown-item
          color: redesign.$thrv3-colors-dark-grey
          font-size: redesign.$thrv3-font-size-16
          min-height: 2rem

          &:hover
            background-color: redesign.$thrv3-colors-royal-blue-light
            color: redesign.$thrv3-colors-light-grey-7

    .retain_payment_method
      &__wrapper
        @extend .custom-control
        @extend .custom-checkbox

      &__input
        @extend .custom-control-input

        &:focus ~ .retain_payment_method__label::before
          box-shadow: none !important

        &:checked ~ .retain_payment_method__label::before
          background-color: redesign.$thrv3-colors-safety-orange !important

      &__label
        @extend .text-thrv-primary
        @extend .custom-control-label

        line-height: 1.625rem

        &::before
          border: 2px solid redesign.$thrv3-colors-safety-orange
          background-color: redesign.$thrv3-colors-white

    .help-text
      margin-bottom: 0.625rem

      p
        color: redesign.$thrv3-colors-dark-grey

    .cancel-button
      @include redesign.thrv3-button-linked(redesign.$thrv3-colors-light-grey-7, redesign.$thrv3-colors-light-grey-33)

      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-regular
      border-radius: 0.3125rem
      height: 2rem
      padding: 0.375rem 0.75rem
      line-height: 1.25rem

      &:hover
        color: redesign.$thrv3-colors-light-grey-35

    .confirm-button
      @include redesign.thrv3-button-primary-orange

      font-size: redesign.$thrv3-font-size-16
      border-radius: 0.3125rem
      height: 2rem
      padding: 0.375rem 0.75rem
      line-height: 1.25rem
      margin-left: 0.625rem

      &:disabled
        cursor: not-allowed

    .submit_buttons
      &__container
        display: flex
        justify-content: center
        margin: 4.0625rem auto 1rem

#updateCardDetailsModal
  @include payment_details_form

  .modal-dialog
    max-width: 38.625rem

  .modal-body
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400
    color: redesign.$thrv3-colors-dark-grey
    padding: 2rem

    .modal-title
      text-align: center
      font-size: redesign.$thrv3-font-size-20
      font-weight: redesign.$thrv3-font-weight-bold
      padding-bottom: 1.25rem

    .card-number
      &__container
        display: flex

      &__label
        flex-basis: 7.5rem
        text-align: left

    .payment_details_form
      &__actions
        &__container
          margin-top: 0.875rem
          display: flex
          justify-content: center

        &__submit_button
          @include redesign.thrv3-button-primary-lg(redesign.$thrv3-colors-safety-orange)

          width: 3.5625rem
          height: 2rem
          margin-left: 0.625rem
          border-radius: 0.3125rem
          padding: 0.375rem 0.75rem
          font-size: redesign.$thrv3-font-size-16
          box-shadow: 0 2px 2px redesign.$thrv3-colors-shadow-4
          line-height: 1.25rem

        &__cancel_button
          @include redesign.thrv3-button-linked(redesign.$thrv3-colors-light-grey-7, redesign.$thrv3-colors-light-grey-33)

          width: 5.375rem
          height: 2rem
          border-radius: 0.1875rem
          padding: 0.375rem 0.75rem
          font-size: redesign.$thrv3-font-size-16
          font-weight: redesign.$thrv3-font-weight-regular
          line-height: 1.25rem

          &:hover
            color: redesign.$thrv3-colors-light-grey-35

#upgradePlanModal, #upgradeMemberModal
  @include payment_details_form

  .modal-dialog
    max-width: 38.625rem

  .modal-body
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400
    color: redesign.$thrv3-colors-dark-grey
    padding: 2rem
    max-height: 50rem

    .modal-title
      text-align: center
      font-size: redesign.$thrv3-font-size-20
      font-weight: redesign.$thrv3-font-weight-bold
      padding-bottom: 1.25rem

    .card-number, .upgrade-info, .seats-info, .member-info
      &__container
        display: flex
        padding-bottom: 1rem

      &__label
        flex-basis: 7.5rem
        text-align: left

    .total-amount-info
      &__wrapper
        text-align: center
        padding-top: 1rem

      &__total_amount
        padding: 1rem 0
        font-size: redesign.$thrv3-font-size-40
        font-weight: redesign.$thrv3-font-weight-bold

      &__first_container, &__second_container
        line-height: 1rem

    .payment_details_form
      &__actions
        &__container
          margin: 3rem 0 1rem 0
          display: flex
          justify-content: center

        &__submit_button
          @include redesign.thrv3-button-primary-lg(redesign.$thrv3-colors-safety-orange)

          width: 5.375rem
          height: 2rem
          margin-left: 0.625rem
          border-radius: 0.3125rem
          padding: 0.375rem 0.75rem
          font-size: redesign.$thrv3-font-size-16
          box-shadow: 0 2px 2px redesign.$thrv3-colors-shadow-4
          line-height: 1.25rem

        &__cancel_button
          @include redesign.thrv3-button-linked(redesign.$thrv3-colors-light-grey-7, redesign.$thrv3-colors-light-grey-33)

          width: 5.375rem
          height: 2rem
          border-radius: 0.1875rem
          padding: 0.375rem 0.75rem
          font-size: redesign.$thrv3-font-size-16
          font-weight: redesign.$thrv3-font-weight-regular
          line-height: 1.25rem

          &:hover
            color: redesign.$thrv3-colors-light-grey-35

#resubscribeMemberModal
  .modal-dialog
    max-width: 37.5rem

  .modal-content
    box-shadow: 0 0.1875rem 1.25rem redesign.$thrv3-colors-shadow-3
    border-radius: 0.5rem

  .modal-body
    text-align: center
    padding: 2rem 1rem

    .modal-title
      font-size: redesign.$thrv3-font-size-20
      font-weight: redesign.$thrv3-font-weight-bold
      color: redesign.$thrv3-colors-dark-grey

    .user-name
      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-bold
      color: redesign.$thrv3-colors-dark-grey
      word-break: break-all
      padding-top: 1.5rem

    .confirm-button
      @include redesign.thrv3-button-primary-orange

      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-regular
      border-radius: 0.3125rem
      height: 2rem
      padding: 0.375rem 0.75rem
      line-height: 1.25rem
      margin-left: 0.625rem

    .cancel-button
      @include redesign.thrv3-button-linked(redesign.$thrv3-colors-light-grey-7, redesign.$thrv3-colors-light-grey-33)

      font-size: redesign.$thrv3-font-size-14
      font-weight: redesign.$thrv3-font-weight-regular
      border-radius: 0.3125rem
      height: 2rem
      padding: 0.375rem 0.75rem
      line-height: 1.25rem

    .submit_buttons
      &__container
        padding-top: 2.25rem
