// Colors
$thrv3-colors-black: rgba(0, 0, 0, 1)
$thrv3-colors-black-2: rgba(26, 28, 33, 1)
$thrv3-colors-dark-grey: rgba(57, 57, 57, 1)
$thrv3-colors-dark-grey-2: rgba(74, 74, 74, 1)
$thrv3-colors-dark-grey-3: rgba(33, 37, 41, 1)
$thrv3-colors-grey: rgba(122, 129, 136, 1)
$thrv3-colors-grey-2: rgba(130, 130, 130, 1)
$thrv3-colors-grey-3: rgba(151, 151, 151, 1)
$thrv3-colors-light-grey: rgba(201, 201, 201, 1)
$thrv3-colors-light-grey-2: rgba(249, 250, 252, 1)
$thrv3-colors-light-grey-3: rgba(248, 250, 255, 1)
$thrv3-colors-light-grey-4: rgba(193, 193, 193, 1)
$thrv3-colors-light-grey-5: rgba(222, 226, 230, 1)
$thrv3-colors-light-grey-6: rgba(248, 249, 251, 1)
$thrv3-colors-light-grey-7: rgba(156, 172, 198, 1)
$thrv3-colors-light-grey-8: rgba(240, 246, 255, 1)
$thrv3-colors-light-grey-9: rgba(233, 241, 255, 1)
$thrv3-colors-light-grey-10: rgba(237, 240, 250, 1)
$thrv3-colors-light-grey-11: rgba(156, 172, 198, 0.12)
$thrv3-colors-light-grey-12: rgba(99, 116, 143, 1)
$thrv3-colors-light-grey-13: rgba(237, 243, 254, 1)
$thrv3-colors-light-grey-14: rgba(235, 239, 248, 1)
$thrv3-colors-light-grey-15: rgba(214, 220, 229,1)
$thrv3-colors-light-grey-16: rgba(211, 215, 223, 1)
$thrv3-colors-light-grey-17: rgba(249, 250, 250, 1)
$thrv3-colors-light-grey-18: rgba(155, 155, 155, 1)
$thrv3-colors-light-grey-19: rgba(241, 246, 255, 1)
$thrv3-colors-light-grey-20: rgba(202, 202, 202, 1)
$thrv3-colors-light-grey-21: rgba(236, 236, 236, 1)
$thrv3-colors-light-grey-22: rgba(190, 192, 191, 1)
$thrv3-colors-light-grey-23: rgba(229, 229, 229, 1)
$thrv3-colors-light-grey-24: rgba(248, 248, 248, 1)
$thrv3-colors-light-grey-25: rgba(230, 230, 230, 1)
$thrv3-colors-light-grey-26: rgba(228, 228, 228, 1)
$thrv3-colors-light-grey-27: rgba(220, 229, 247, 1)
$thrv3-colors-light-grey-28: rgba(235, 238, 244, 1)
$thrv3-colors-light-grey-29: rgba(237, 238, 242, 1)
$thrv3-colors-light-grey-30: rgba(123, 129, 135, 1)
$thrv3-colors-light-grey-31: rgba(224, 233, 251, 1)
$thrv3-colors-light-grey-32: rgba(204, 204, 204, 1)
$thrv3-colors-light-grey-33: rgba(156, 172, 198, 0.2)
$thrv3-colors-light-grey-34: rgba(156, 172, 198, 0.21)
$thrv3-colors-light-grey-35: rgba(112, 122, 138, 1)
$thrv3-colors-light-grey-36: rgba(243, 245, 248, 1)
$thrv3-colors-light-grey-37: rgba(52, 55, 65, 1)
$thrv3-colors-navy: rgba(71, 123, 231, 1)
$thrv3-colors-light-navy: rgba(116, 155, 237, 1)
$thrv3-colors-light-navy-2: rgba(116, 155, 237, 0.2)
$thrv3-colors-light-steel-blue: rgba(194, 207, 227, 30)
$thrv3-colors-mild-white: rgba(246, 247, 250, 1)
$thrv3-colors-mild-white-2: rgba(250, 251, 252, 1)
$thrv3-colors-royal-blue: rgba(98, 143, 235, 1)
$thrv3-colors-royal-blue-light: rgba(98, 143, 235, 0.2)
$thrv3-colors-safety-orange: rgba(255, 103, 0, 1)
$thrv3-colors-safety-orange-light: rgba(255, 103, 0, 0.2)
$thrv3-colors-safety-orange-mild: rgba(255, 103, 0, 0.4)
$thrv3-colors-pink-light: rgba(255, 210, 189, 1)
$thrv3-colors-pink-light-2: rgba(254, 237, 224, 1)
$thrv3-colors-border-orange: rgba(255, 178, 141, 1)
$thrv3-colors-hover-button-primary: rgba(203, 82, 0, 1)
$thrv3-colors-hover-button-primary2: rgba(203, 82, 0, 0.4)
$thrv3-colors-solitude: rgba(231, 238, 255, 1)
$thrv3-colors-solitude-light: rgba(231, 238, 255, 1)
$thrv3-colors-vivid-cyan: rgba(88, 195, 174, 1)
$thrv3-colors-white: rgba(255, 255, 255, 1)
$thrv3-colors-yellow: rgba(255, 199, 0, 1)
$thrv3-colors-tooltip-background-blue: rgba(18, 48, 95, 1)
$thrv3-colors-yellow-2: rgba(255, 153, 0, 1)
$thrv3-colors-yellow-3: rgba(255, 247, 220, 1)
$thrv3-colors-light-blue: rgba(137, 172, 227, 1)
$thrv3-colors-light-blue-2: rgba(227, 235, 249, 1)
$thrv3-colors-orange: rgba(253, 104, 33, 1)
$thrv3-colors-orange-2: rgba(255, 225, 204, 1)
$thrv3-colors-orange-3: rgba(212, 86, 0, 1)
$thrv3-colors-orange-4: rgba(252, 103, 32, 1)
$thrv3-colors-font-blue: rgba(28, 110, 254, 1)
$thrv3-colors-light-font-blue: rgba(201, 218, 247, 1)
$thrv3-colors-red: rgba(254, 11, 11, 1)
$thrv3-colors-red-2: rgba(199, 21, 21, 1)
$thrv3-colors-red-light: rgba(254, 11, 11, 0.2)
$thrv3-colors-red-light2: rgba(254, 11, 11, 0.4)
$thrv3-colors-red-light3: rgba(255, 206, 206, 1)
$thrv3-colors-green: rgba(4, 198, 173, 1)
$thrv3-colors-green-2: rgba(208, 249, 243, 1)
$thrv3-colors-blue: rgba(116, 155, 237, 1)
$thrv3-colors-blue-2: rgba(66, 125, 229, 1)
$thrv3-colors-transparent: rgba(0, 0, 0, 0.2)
$thrv3-colors-transparent-2: rgba(0, 0, 0, 0.1)
$thrv3-colors-border: rgba(241, 241, 241, 1)
$thrv3-colors-shadow: rgba(137, 172, 227, 0.08)
$thrv3-colors-shadow-2: rgba(137, 172, 227, 0)
$thrv3-colors-shadow-3: rgba(18, 48, 95, 0.1)
$thrv3-colors-shadow-4: rgba(152, 162, 179, 0.15)
$thrv3-nav-tabs-border-color: rgba(238, 240, 244, 1)
$thrv3-colors-border-1: rgba(216, 222, 232, 1)
$thrv3-feature-thesis-border: rgba(237, 242, 251, 1)
$thrv3-colors-highlight-blue: rgba(128, 189, 255, 1)
$thrv3-colors-label-blue: rgba(96, 144, 234, 1)
$thrv3-colors-tan: rgba(172, 69, 0, 1)
$thrv-colors-card-border: rgba(0, 0, 0, 0.125)
$thrv3-colors-hr-border: rgba(210, 213, 219, 1)
$thrv3-color-dropdown-shadow: rgba(6, 39, 97, 0.08)
$thrv3-color-dropdown-shadow-2: rgba(9, 30, 66, 0.1)
$thrv3-modal-background-color: rgba(18, 48, 95, 0.45)

$thrv3-fonts-fallback: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default
$thrv3-fonts-proxima-nova: "Proxima Nova", $thrv3-fonts-fallback

$foundation-emails-font: "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default

$thrv3-font-size-title: 1.5625rem
$thrv3-font-size-subtitle: 1.4rem
$thrv3-font-size-regular: 1rem
$thrv3-font-size-course-header: 1.875rem
$thrv3-font-size-header-3: 1.375rem

$thrv3-line-height-title: 1.875rem
$thrv3-line-height-regular: 1.625rem
$thrv3-line-height-course-header: 2.3125rem
$thrv3-line-height-header-3: 1.875rem

$thrv3-shadows-default: 0px 4px 60px rgba(0, 0, 0, 0.12)

$thrv3-font-weight-regular: 300
$thrv3-font-weight-400: 400
$thrv3-font-weight-500: 500
$thrv3-font-weight-550: 550
$thrv3-font-weight-600: 600
$thrv3-font-weight-bold: bold

$thrv3-font-size-10: 10px
$thrv3-font-size-11: 11px
$thrv3-font-size-12: 12px
$thrv3-font-size-13: 13px
$thrv3-font-size-14: 14px
$thrv3-font-size-15: 15px
$thrv3-font-size-16: 16px
$thrv3-font-size-17: 17px
$thrv3-font-size-18: 18px
$thrv3-font-size-19: 19px
$thrv3-font-size-20: 20px
$thrv3-font-size-22: 22px
$thrv3-font-size-24: 24px
$thrv3-font-size-25: 25px
$thrv3-font-size-32: 32px
$thrv3-font-size-35: 35px
$thrv3-font-size-37: 37px
$thrv3-font-size-40: 40px

$thrv3-grid-breakpoints: ("md": 768px, "ml": 1300px, "lg": 1440px)
