@use "../core/variables"
@use "../core/buttons"

.new_job
  &__container
    padding: 1.4375rem 2.5rem

  &__header
    font-size: variables.$thrv3-font-size-25
    font-weight: variables.$thrv3-font-weight-bold
    color: variables.$thrv3-colors-dark-grey
    margin-bottom: 2.5rem

  &__info_tooltip
    i
      color: variables.$thrv3-colors-tooltip-background-blue

  &__customer_name
    color: variables.$thrv3-colors-dark-grey
    font-size: variables.$thrv3-font-size-16

    &__suffix
      color: variables.$thrv3-colors-light-grey-7
      font-size: variables.$thrv3-font-size-16
      padding-left: 0.625rem

  &_form
    margin-top: 2.25rem

    &__name
      &__label
        color: variables.$thrv3-colors-light-grey-7
        font-size: variables.$thrv3-font-size-17
        font-weight: variables.$thrv3-font-weight-500

      &__input
        color: variables.$thrv3-colors-light-grey-7
        font-size: variables.$thrv3-font-size-16
        font-weight: variables.$thrv3-font-weight-regular
        border: 1px solid variables.$thrv3-colors-light-grey-7
        height: 3.125rem
        border-radius: 0.5rem

        &:focus
          color: variables.$thrv3-colors-light-grey-7

    &__actions
      margin-top: 3.25rem
      flex-direction: row-reverse

    &__submit_button
      @include buttons.button-font-and-paddings-1

      width: 7.5rem
      margin-right: 1rem
      margin-left: 0.5rem

      font-size: variables.$thrv3-font-size-16
      font-weight: variables.$thrv3-font-weight-500

      color: variables.$thrv3-colors-white
      background-color: variables.$thrv3-colors-safety-orange
      border: 0.5px solid variables.$thrv3-colors-safety-orange
      border-radius: 8px

      &:hover
        color: variables.$thrv3-colors-white
        background-color: variables.$thrv3-colors-hover-button-primary2
        border-color: variables.$thrv3-colors-hover-button-primary2

      &:active
        color: variables.$thrv3-colors-white
        background-color: variables.$thrv3-colors-royal-blue
        border-color: variables.$thrv3-colors-royal-blue

    &__cancel_button
      @include buttons.thrv3-button-linked(variables.$thrv3-colors-red, variables.$thrv3-colors-red-light2)

      width: 7.5rem
      font-size: variables.$thrv3-font-size-16
      font-weight: variables.$thrv3-font-weight-500
      border-radius: 0.5rem
