@use "../redesign/core/bootstrap_overrides"

@include bootstrap_overrides.media-breakpoint-up(lg)
  .modal
    .modal-dialog
      &.modal-lg
        max-width: 80%
      .modal-title
        width: 100%
        text-align: center
      .modal-body
        overflow-y: auto
        max-height: 75vh

.modal
  .modal-dialog
    &.modal-md
      max-width: 45%
