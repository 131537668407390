@use "../../redesign/all" as redesign

.purchase-wrapper
  &--with-bg-image
    background-image: url("../../../images/team_orange.jpg")
    background-size: cover
    background-repeat: no-repeat
    background-color: redesign.$thrv3-colors-safety-orange
    background-position: center

  @include redesign.media-breakpoint-up(lg)
    &__modal-workshop
      max-width: 80%

  @include redesign.media-breakpoint-up(xl)
    &__modal-workshop
      max-width: 65rem

  footer
    background-color: redesign.$thrv3-colors-light-grey-24
    padding: 5rem 0
    border-top: 2px solid redesign.$thrv3-colors-light-grey-25

.purchase
  @include redesign.make-row
  justify-content: center
  line-height: 1.6

  h1
    font-size: 2.25em
    margin-bottom: map-get(redesign.$spacers, 2)

  h2
    font-size: 1.25em

  &__content
    @include redesign.make-row

    &__left
      color: redesign.$thrv3-colors-white
      p
        color: redesign.$thrv3-colors-white

      &__logo
        margin-bottom: map-get(redesign.$spacers, 4)

    &__left, &__right
      @include redesign.make-col-ready
      @include redesign.make-col(6)
      @include redesign.media-breakpoint-down(md)
        @include redesign.make-col(12)

      &__product_purchase
        background-color: redesign.$thrv3-colors-white

      &__course_purchase, &__thanks
        text-align: center
        background-color: redesign.$thrv3-colors-white
        padding: 20px 30px

      .thrv
        word-spacing: 0

  &__form
    @include redesign.make-col-ready
    @include redesign.make-col(12)

    .form-control
      border: 0
      border-radius: 0
      background-color: redesign.$thrv3-colors-light-grey-26

    &--course-price
      display: none

    &-group
      @include redesign.make-col-ready

    &--exp, &--cvv
      @include redesign.make-col-ready
      @include redesign.make-col(6)

    &--total-label, &--total-price
      @include redesign.make-col-ready
      @include redesign.make-col(6)

    &--total-label
      text-align: right
    &--total-price
      text-align: left

    &--taking-course
      @include redesign.make-col-ready
      label
        text-align: right
      div
        text-align: left

    &--seats-label
      @include redesign.make-col-ready
      @include redesign.make-col(6)
      text-align: right

    &--seats-input
      @include redesign.make-col-ready
      @include redesign.make-col(6)

    &--terms-accepted
      .form-check
        text-align: left

  &__button
    @extend .btn-thrv-secondary

    display: block
    width: 100%
