@use "../redesign/core/variables"

.question-step
  padding-bottom: 3rem

  b
    color: variables.$thrv3-colors-safety-orange

  &__example
    padding-top: 2rem
