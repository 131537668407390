@use "../redesign/core/bootstrap_overrides"

@forward "base"
@forward "buttons"
@forward "tables"
@forward "modals"
@forward "footer"
@forward "inputs/all"
@forward "question_step"

.form-error
  color: bootstrap_overrides.$danger
