@use "../../redesign/core/bootstrap_overrides"

.terms-and-conditions-form-group
  &__label
    margin-bottom: 0

    @include bootstrap_overrides.make-col-ready

    @include bootstrap_overrides.media-breakpoint-up(xs)
      @include bootstrap_overrides.make-col(12)

      text-align: center

    @include bootstrap_overrides.media-breakpoint-up(sm)
      @include bootstrap_overrides.make-col(10)

      text-align: center

    @include bootstrap_overrides.media-breakpoint-up(md)
      @include bootstrap_overrides.make-col(10)

      text-align: left

    @include bootstrap_overrides.media-breakpoint-up(lg)
      @include bootstrap_overrides.make-col(10)

      text-align: left

    @include bootstrap_overrides.media-breakpoint-up(xl)
      @include bootstrap_overrides.make-col(10)

      text-align: left

  &__input-wrapper
    @include bootstrap_overrides.make-col-ready

    @include bootstrap_overrides.media-breakpoint-up(xs)
      @include bootstrap_overrides.make-col(12)

      text-align: center

    @include bootstrap_overrides.media-breakpoint-up(sm)
      @include bootstrap_overrides.make-col(2)

      text-align: left

    @include bootstrap_overrides.media-breakpoint-up(md)
      @include bootstrap_overrides.make-col(2)

      text-align: left

    @include bootstrap_overrides.media-breakpoint-up(lg)
      @include bootstrap_overrides.make-col(2)

      text-align: left

    @include bootstrap_overrides.media-breakpoint-up(xl)
      @include bootstrap_overrides.make-col(2)

      text-align: left

  &__input
    width: fit-content

.purchase__form
  .terms-and-conditions-form-group
    &__label
      @include bootstrap_overrides.make-col-ready

      @include bootstrap_overrides.media-breakpoint-up(xs)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(sm)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(md)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(lg)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(xl)
        @include bootstrap_overrides.make-col(6)

    &__input-wrapper
      text-align: left

      @include bootstrap_overrides.make-col-ready

      @include bootstrap_overrides.media-breakpoint-up(xs)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(sm)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(md)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(lg)
        @include bootstrap_overrides.make-col(6)

      @include bootstrap_overrides.media-breakpoint-up(xl)
        @include bootstrap_overrides.make-col(6)
