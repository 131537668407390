@use "../../redesign/all" as redesign

.user-lesson
  @include redesign.make-row
  padding-top: 2rem
  margin: 0

  // Sidebar styles
  &__sidebar
    @include redesign.make-col-ready

    @include redesign.media-breakpoint-up(sm)
      @include redesign.make-col(2)

    background-color: redesign.$thrv3-colors-white
    border-right: 1px solid redesign.$thrv3-colors-light-grey-3

    @include redesign.media-breakpoint-down(sm)
      @include redesign.make-col(4)

    &__link
      text-decoration: underline

      &--active
        color: redesign.$thrv3-colors-black
        text-decoration: none

        &:hover, &:visited
          color: redesign.$thrv3-colors-black
          cursor: default

    &--label
      padding-left: 0.25rem

  // Video block styles
  &__video-block
    @include redesign.make-col-ready

    @include redesign.media-breakpoint-up(sm)
      @include redesign.make-col(9)

    @include redesign.media-breakpoint-down(sm)
      @include redesign.make-col(8)

    margin: 0 auto

    &__head
      text-align: center

    &__review, &__null_review, &__next_lesson
      p
        color: #000

        cite
          color: redesign.$thrv3-colors-safety-orange
          font-style: normal

      text-align: left

      .collection_radio_buttons
        padding-left: 0.45rem

    &__review
      &__table-question
        @include redesign.table-question
