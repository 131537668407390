@use "../../redesign/all" as redesign

.fta-step
  &__competitor-products-list
    margin-bottom: map-get(redesign.$spacers, 2)

  &__accordion
    .card
      border: redesign.$card-border-width solid redesign.$thrv3-colors-light-grey-5 !important
      @include redesign.border-radius(redesign.$card-border-radius)
      margin-bottom: map-get(redesign.$spacers, 2)

  &__competitor-product-card-heading
    margin-bottom: map-get(redesign.$spacers, 0)
