@use "../../redesign/core/bootstrap_overrides"

.taking-course-form-group
  &__label
    @include bootstrap_overrides.make-col-ready

    @include bootstrap_overrides.media-breakpoint-up(xs)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(sm)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(md)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(lg)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(xl)
      @include bootstrap_overrides.make-col(6)

  &__input-wrapper
    text-align: left

    @include bootstrap_overrides.make-col-ready

    @include bootstrap_overrides.media-breakpoint-up(xs)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(sm)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(md)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(lg)
      @include bootstrap_overrides.make-col(6)

    @include bootstrap_overrides.media-breakpoint-up(xl)
      @include bootstrap_overrides.make-col(6)
