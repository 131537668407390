@use "../redesign/core/variables"
@use "../redesign/core/bootstrap_overrides"

= btn-thrv-primary
  @include bootstrap_overrides.button-variant(variables.$thrv3-colors-safety-orange, variables.$thrv3-colors-safety-orange)
  @include bootstrap_overrides.button-size(bootstrap_overrides.$btn-padding-y, bootstrap_overrides.$btn-padding-x, bootstrap_overrides.$font-size-base, normal, bootstrap_overrides.$btn-border-radius-lg)

= btn-thrv-secondary
  @include bootstrap_overrides.button-variant(variables.$thrv3-colors-blue-2, variables.$thrv3-colors-blue-2)
  @include bootstrap_overrides.button-size(bootstrap_overrides.$btn-padding-y, bootstrap_overrides.$btn-padding-x, bootstrap_overrides.$font-size-base, normal, bootstrap_overrides.$btn-border-radius-lg)

= btn-thrv-outline-dark
  @extend .btn
  @include bootstrap_overrides.button-outline-variant(bootstrap_overrides.$dark)
  @include bootstrap_overrides.button-size(bootstrap_overrides.$btn-padding-y, bootstrap_overrides.$btn-padding-x, bootstrap_overrides.$font-size-base, normal, bootstrap_overrides.$btn-border-radius-lg)
