@use "mixins/all" as core_mixins
@use "../../redesign/all" as redesign

.fta-step
  &__primary-button
    @include redesign.btn-thrv-primary

  &__outline-dark-button
    @include redesign.btn-thrv-outline-dark

  input[type="number"]
    width: max-content

  abbr[title="required"]
    display: none

  &__card
    @include core_mixins.thrv-card

  &__table
    @include core_mixins.app-table

  &__submit-button
    @include redesign.btn-thrv-primary
