@use "../../redesign/all" as redesign

@mixin profile-label
  font-size: redesign.$thrv3-font-size-16
  color: redesign.$thrv3-colors-dark-grey

.profile
  @include redesign.content-left-and-right-paddings
  margin-top: map-get(redesign.$spacers, 4)
  font-size: redesign.$thrv3-font-size-16
  font-weight: redesign.$thrv3-font-weight-400

  &__header
    &__title
      @include redesign.thrv3-fonts-medium
      font-size: redesign.$thrv3-font-size-25
      font-weight: redesign.$thrv3-font-weight-500
      color: redesign.$thrv3-colors-dark-grey

  &__header, &__input, &__button
    text-align: left
    display: flex

  &__content
    &__wrapper
      width: 50%

  &__form
    padding-right: 8rem

  &__inputs
    &__wrapper
      display: flex

  &__input
    &__wrapper
      position: relative
      width: 100%

      &:last-child
        margin-left: 0.625rem

    &__saved_text
      position: absolute
      right: 1rem
      top: 40%
      font-size: redesign.$thrv3-font-size-16
      color: redesign.$thrv3-colors-light-grey-7

    &.form-control
      border: 0.5px solid redesign.$thrv3-colors-light-grey-7
      border-radius: 0.3125rem
      font-size: redesign.$thrv3-font-size-16
      padding: 0.625rem 4rem 0.625rem 0.5rem
      height: 2.5rem

      &.is-valid
        background-image: none

      &:focus
        border-color: redesign.$thrv3-colors-light-grey-7
        box-shadow: 0 0 0 .2rem redesign.$input-btn-focus-color

  &__label
    @include profile-label

    margin-bottom: 0

  &__no_subscription_banner
    &__wrapper
      @include redesign.media-breakpoint-up(sm)
      @include redesign.media-breakpoint-up(lg)
      @include redesign.media-breakpoint-up(md)
      @include redesign.media-breakpoint-up(xs)

    &__container
      @extend .alert
      @extend .alert-warning
      @extend .alert-dismissible
      @extend .text-center
      padding: 0.5rem !important

  &__delete_account_btn
    margin-left: auto
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400
    line-height: 1.25rem
    color: redesign.$thrv3-colors-red

    &:hover
      color: redesign.$thrv3-colors-red-2

  &__contact_us
    &__wrapper
      font-size: redesign.$thrv3-font-size-16
      color: redesign.$thrv3-colors-dark-grey
      margin: 0.3rem 0 0.3rem 0

    &__link
      font-weight: redesign.$thrv3-font-weight-400

  &__email
    width: 100%
    display: flex
    align-items: center
    font-size: redesign.$thrv3-font-size-16
    color: redesign.$thrv3-colors-dark-grey
    background-color: redesign.$thrv3-colors-mild-white
    margin-top: 0.625rem
    padding: 0.625rem 0.5rem
    border-radius: 0.3125rem

    &__wrapper
      padding-top: 0.5rem
      padding-bottom: 0.75rem

    &__label
      @include profile-label

      line-height: 1.0625rem

    &__container
      display: flex

  &__add_company_link
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-400
    color: redesign.$thrv3-colors-safety-orange

    &:hover
      color: redesign.$thrv3-colors-orange-3

    &__wrapper
      width: 8rem
      text-align: right
      margin-top: 0.625rem
      align-self: center
      flex-shrink: 0

  &__actions
    margin-top: 1rem
    display: flex
    flex-direction: row-reverse

  &__submit_button
    @include redesign.thrv3-button-primary-lg(redesign.$thrv3-colors-safety-orange)

    height: 2rem
    margin-left: 0.625rem
    border-radius: 0.3125rem
    padding: 0.375rem 0.75rem
    font-size: redesign.$thrv3-font-size-16
    box-shadow: 0 2px 2px redesign.$thrv3-colors-shadow-4
    line-height: 1.25rem

  &__cancel_button
    @include redesign.thrv3-button-linked(redesign.$thrv3-colors-light-grey-7, redesign.$thrv3-colors-light-grey-33)

    height: 2rem
    border-radius: 0.1875rem
    padding: 0.375rem 0.75rem
    font-size: redesign.$thrv3-font-size-16
    font-weight: redesign.$thrv3-font-weight-regular
    line-height: 1.25rem

    &:hover
      color: redesign.$thrv3-colors-light-grey-35

#subscriptionCancellationModal
  .modal-content
    box-shadow: 0 0.5rem 1.25rem redesign.$thrv3-colors-shadow-3
    border-radius: 0.5rem

  .modal-body
    text-align: center
    font-size: redesign.$thrv3-font-size-16
    color: redesign.$thrv3-colors-dark-grey

    .modal-title
      font-size: redesign.$thrv3-font-size-25
      font-weight: redesign.$thrv3-font-weight-bold
      padding: 0.5rem 0 1rem 0

    .subscription_cancellation_email
      font-weight: redesign.$thrv3-font-weight-bold
      color: redesign.$thrv3-colors-red

      &:hover
        color: redesign.$thrv3-colors-red-light2

    .close_button
      @include redesign.thrv3-button-linked(redesign.$thrv3-colors-light-grey-7, redesign.$thrv3-colors-light-grey-33)

      width: 5.375rem
      height: 2rem
      border-radius: 0.1875rem
      padding: 0.375rem 0.75rem
      font-size: redesign.$thrv3-font-size-16
      font-weight: redesign.$thrv3-font-weight-regular
      line-height: 1.25rem
      margin-top: 2rem

      &:hover
        color: redesign.$thrv3-colors-light-grey-35
