@use "../../redesign/all" as redesign

@forward "projects"

@forward "mixins/all"
@forward "core/all"

@forward "approval"
@forward "competition"
@forward "fta-step"
@forward "organizations"
@forward "progress-bar"
@forward "session"
@forward "company-settings"
@forward "profile"
@forward "billing"
@forward "purchases"
@forward "bulk-invitations"

.form-error
  color: redesign.$danger
