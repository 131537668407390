@use "../../redesign/all" as redesign

.sign-up
  @include redesign.make-row
  justify-content: center

  &__content
    @include redesign.make-col-ready

    @include redesign.media-breakpoint-up(xs)
      @include redesign.make-col(12)

    @include redesign.media-breakpoint-up(sm)
      @include redesign.make-col(12)

    @include redesign.media-breakpoint-up(md)
      @include redesign.make-col(4)

    @include redesign.media-breakpoint-up(lg)
      @include redesign.make-col(4)

    @include redesign.media-breakpoint-up(xl)
      @include redesign.make-col(4)

  &__header, &__input, &__button, &__hint
    text-align: center

  &__button
    display: block
    @include redesign.btn-thrv-primary
