html
  position: relative
  min-height: 100%

body
  margin-bottom: 0px

footer
  bottom: 0
  width: 100%
  height: 60px
  margin-bottom: 0.5rem
  margin-top: 100px

  ul > li
    list-style-type: none
    float: left
    color: #000
    ::after
      color: #000
      content: "|"
      margin: 0 0.5rem 0 0.5rem

    a
      color: #000
