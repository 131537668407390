@use "variables"

$theme-colors: ("thrv-mid-gray": variables.$thrv3-colors-light-grey-20, "thrv-primary": variables.$thrv3-colors-safety-orange, "thrv-secondary": variables.$thrv3-colors-blue-2)

$headings-font-weight: variables.$thrv3-font-weight-550

$border-color:    variables.$thrv3-colors-light-grey-5
$hr-border-color: variables.$thrv3-colors-hr-border

$btn-padding-y-lg: .5rem
$btn-padding-x-lg: .8rem
$btn-padding-y:    .5rem
$btn-padding-x:    1.7rem

$input-placeholder-color: variables.$thrv3-colors-light-grey-18
$input-color:             variables.$thrv3-colors-dark-grey-2
$input-border-color:      variables.$thrv3-colors-grey-3

$font-weight-base: variables.$thrv3-font-weight-regular

$h1-font-size: 1.5rem
$h2-font-size: 1.125rem
$h3-font-size: 1rem

$link-color:            variables.$thrv3-colors-safety-orange
$link-hover-decoration: none

$dropdown-link-color:          variables.$thrv3-colors-dark-grey-2
$dropdown-link-hover-color:    darken($dropdown-link-color, 25%)
$dropdown-link-active-color:   darken($dropdown-link-hover-color, 10%)
$dropdown-link-hover-bg:       variables.$thrv3-colors-pink-light-2
$dropdown-link-active-bg:      variables.$thrv3-colors-pink-light
$dropdown-link-disabled-color: lighten($dropdown-link-color, 40%) !default


@forward "~bootstrap/scss/bootstrap" with ($theme-colors: $theme-colors, $headings-font-weight: $headings-font-weight, $border-color: $border-color, $hr-border-color: $hr-border-color, $btn-padding-y-lg: $btn-padding-y-lg, $btn-padding-x-lg: $btn-padding-x-lg, $btn-padding-y: $btn-padding-y, $btn-padding-x: $btn-padding-x, $input-placeholder-color: $input-placeholder-color, $input-color: $input-color, $input-border-color: $input-border-color, $font-weight-base: $font-weight-base, $h1-font-size: $h1-font-size, $h2-font-size: $h2-font-size, $h3-font-size: $h3-font-size, $link-color: $link-color, $link-hover-decoration: $link-hover-decoration, $dropdown-link-color: $dropdown-link-color, $dropdown-link-hover-color: $dropdown-link-hover-color, $dropdown-link-active-color: $dropdown-link-active-color, $dropdown-link-hover-bg: $dropdown-link-hover-bg, $dropdown-link-active-bg: $dropdown-link-active-bg, $dropdown-link-disabled-color: $dropdown-link-disabled-color)

.dropdown
  .dropdown-item
    font-weight: variables.$thrv3-font-weight-regular

    &.active,
    &:active
      font-weight: variables.$thrv3-font-weight-400

.modal-backdrop
  background-color: variables.$thrv3-modal-background-color
