@use "../../../redesign/all" as redesign

.error-container
  text-align: center
  color: redesign.$thrv3-colors-dark-grey-2

  &__title
    font-size: 1.2rem
    font-weight: redesign.$thrv3-font-weight-bold
    margin: 1rem 0

  &__icon
    font-size: 6rem
    color: redesign.$thrv3-colors-safety-orange
    margin: 0

  &__message
    font-size: 1.2rem
    font-weight: redesign.$thrv3-font-weight-regular
    margin: 1rem 0

.form-error
  color: redesign.$danger
