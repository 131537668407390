@use "../../redesign/all" as redesign

.fta-step
  &__preview-feature-thesis-summary-button
    @include redesign.button-outline-variant(redesign.$thrv3-colors-safety-orange)
    @extend .btn

  &__email-preview
    &__body
      background-color: redesign.$thrv3-colors-white
