@use "../../../redesign/all" as redesign

@mixin app-table
  width: 100%
  max-width: 100%

  thead
    th
      border-bottom: 1.333px solid redesign.$thrv3-colors-hr-border
      padding-top: map-get(redesign.$spacers, 2)
      padding-bottom: map-get(redesign.$spacers, 2)
      padding-left: map-get(redesign.$spacers, 3)
      padding-right: map-get(redesign.$spacers, 3)

  tbody
    td
      vertical-align: top
      border-bottom: 1.333px solid redesign.$thrv3-colors-hr-border
      padding-top: map-get(redesign.$spacers, 3)
      padding-bottom: map-get(redesign.$spacers, 3)
      padding-left: map-get(redesign.$spacers, 3)
      padding-right: map-get(redesign.$spacers, 3)
    tr:last-child
      td
        border-bottom: none
